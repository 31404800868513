


































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsTagNameModal from '@/modules/lists/components/VsTagNameModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsTagCard from '@/components/VsTagCard/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsFixedBottomActions from '@/components/VsFixedBottomActions/Index.vue'
import {
    getTags,
    deleteTagById,
} from '@/api/consoleApi/recipients'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { UserModule } from '@/store/modules/user'
@Component({
    name: 'ImportHistory',
    components: {
        VsFormSection,
        VsTagNameModal,
        VsSectionHeader,
        VsConfirm,
        VsTagCard,
        VsLoader,
        VsFixedBottomActions,
    },
})
export default class extends Vue {
    private loading = false
    private showEmptyState = true
    private tags = []
    private filterTotal = 0
    private total = 0

    private pagination = {
        currentPage: 1,
        itemsPerPage: 20,
        sortedBy: null,
    }

    private filters = {
        search: '',
    }

    $refs!: any

    get listId () {
        return this.$route.params.listId
    }

    get user () {
        return UserModule.user
    }

    get hasTag () {
        return this.user.configuration.rules.tag
    }

    get listsOrderBy () {
        return [
            {
                label: this.$t('lists.index.orderBy.createdAtDesc'),
                value: 'desc',
            },
            {
                label: this.$t('lists.index.orderBy.createdAtAsc'),
                value: 'asc',
            },
        ]
    }

    async mounted () {
        if (!this.hasTag) {
            this.$router.replace({
                name: 'contacts',
            })
            return
        }
        await this.getTotalTags()
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        this.pagination.currentPage = 1
        await this.getTags()
    }

    @Watch('pagination', { immediate: true, deep: true })
    private async getTags () {
        this.loading = true
        try {
            const resp = await getTags(this.listId, this.buildParams())
            this.tags = resp.data.data
            this.total = resp.data.meta.pagination.total
        } catch (e) {
            this.tags = []
            this.total = 0
        }
        this.loading = false
    }

    private async getTotalTags () {
        try {
            const resp = await getTags(this.listId, { limit: 1 })
            if (resp.data.meta.pagination.total > 0) {
                this.showEmptyState = false
                await this.getTags()
            }
        } catch (e) {
            this.showEmptyState = false
        }
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            page: this.pagination.currentPage,
            limit: this.pagination.itemsPerPage,
        }

        if (this.filters.search && this.filters.search.trim() !== '') {
            params.searchFields = 'name:like'
            params.search = this.filters.search
        }

        if (this.pagination.sortedBy) {
            params.sortedBy = this.pagination.sortedBy
        }
        return params
    }

    private async deleteTag (id: any) {
        try {
            await this.$refs.deleteTagConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await deleteTagById(this.listId, id)

            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Etichetta eliminata con successo',
                aspect: VsToastAspectEnum.success,
            })
            if (this.pagination.currentPage === 1) {
                await this.getTags()
            } else {
                this.pagination.currentPage = 1
            }

            if (this.tags.length === 0) this.showEmptyState = true
        } catch (e) {
            this.$refs.deleteTagConfirm.closeConfirm()
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Qualcosa è andato storto',
                aspect: VsToastAspectEnum.alert,
            })
            await this.getTags()
            console.log(e)
        }
        this.loading = false
    }
}
