
































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsTagCard',
    components: {
        VsListingCard,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private tag!: any

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) private hideActions!: boolean
}
